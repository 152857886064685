import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import UButton from '@userbot/components/dist/u-button'
import UIcon from '@userbot/components/dist/u-icon'
import PageLoader from '../page-loader/index.vue'
import ULoader from '../u-loader/index.vue'
import axios from 'axios'

let userbot: any

@Component({
  components: {
    UButton,
    UIcon,
    PageLoader,
    ULoader
  }
})
export default class PreviewChat extends Vue {
  @Prop({
    type: Object,
    required: true,
    default: () => {
      return {}
    }
  })
  data: any

  @Ref()
  readonly iframe!: HTMLIFrameElement

  @Ref()
  readonly img!: HTMLImageElement

  gettingLink = false
  destroyed = false
  elem: null | HTMLImageElement = null
  loaded = false

  get isUrlMode () {
    return this.data.mode === 'url'
  }

  get style () {
    return this.isUrlMode
      ? {}
      : {
          'background-image': `url(${this.data.background})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }
  }

  get isDemo () {
    const queryUrl = window.location.search
    const urlParams = new URLSearchParams(queryUrl)
    return urlParams.get('demo') === 'true'
  }

  async mounted () {
    const queryUrl = window.location.search
    const urlParams = new URLSearchParams(queryUrl)
    this.iframe?.addEventListener('load', this.setUserbot)
    if (this.isDemo && urlParams.get('fileId')) {
      const response = await axios.post(`${process.env.VUE_APP_REST_API_URL}${process.env.VUE_APP_ATTACHMENTS_RETRIEVE}`, {
        bot: this.data.secretKey,
        fileId: +urlParams.get('fileId')!
      })
      const fileBuffer = Uint8Array.from(response.data.result.data)
      const content = new Blob([fileBuffer.buffer])
      const encodedUri = window.URL.createObjectURL(content)
      this.data.background = encodedUri
    }
    this.elem = document.createElement('img')
    this.elem.setAttribute('src', this.data.background)
    this.elem.style.opacity = '0'
    this.$el.appendChild(this.elem)
    this.elem.addEventListener('load', this.setUserbot)
    this.elem.addEventListener('error', () => {
      console.log('errore')
    })
  }

  beforeDestroy () {
    (window as any).Userbot()?.destroy()
    this.iframe?.removeEventListener('load', this.setUserbot)
    this.elem?.removeEventListener('load', this.setUserbot)
    this.loaded = false
    this.destroyed = true
  }

  setUserbot () {
    this.loaded = true
    setTimeout(() => {
      if (!this.destroyed) {
        if (this.data.sourcecode) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.innerHTML = this.data.sourcecode
          document.body.append(script)
        } else {
          userbot = (window as any).Userbot({
            key: this.data.secretKey,
            customerToken: this.data.customerToken,
            avatar: this.data.avatar
              ? {
                  enabled: true,
                  opened: true,
                  type: 'video',
                  name: ''
                }
              : {},
            chat: {
              opened: this.data.chatOpen,
              minified: this.data.showPreviewMessage
            }
          })
        }
      }
    }, 2000)
  }

  async editChatOptions () {
    this.$emit('editChatOptions')
    if (userbot) {
      await userbot.destroy()
    }
  }

  async getLink () {
    if (!this.gettingLink) {
      this.gettingLink = true
      try {
        let parameters = ''
        if (this.data.sourcecode) {
          parameters = `?demo=true&secretKey=${this.data.secretKey}${this.data.mode !== 'screen' ? `&background=${this.data.background}` : ''}&mode=${this.data.mode}&sourcecode=${btoa(this.data.sourcecode)}`
        } else {
          parameters = `?demo=true&secretKey=${this.data.secretKey}&customerToken=${this.data.customerToken}${this.data.mode !== 'screen' ? `&background=${this.data.background}` : ''}&showPreviewMessage=${this.data.showPreviewMessage}&chatOpen=${this.data.chatOpen}&avatar=${this.data.avatar}&mode=${this.data.mode}`
        }
        if (this.data.file && this.data.mode === 'screen') {
          console.log('test', this.data.secretKey)
          console.log('data', this.data)
          try {
            const response = await axios.post(`${process.env.VUE_APP_REST_API_URL}${process.env.VUE_APP_ATTACHMENTS_STORE}`, {
              bot: process.env.VUE_APP_ATTACHMENTS_BOT,
              blob: this.data.file.blob,
              name: this.data.filename,
              mime: this.data.file.mime
            })
            console.log('respomse', response)
            if (response.status === 200 || response.status === 201) {
              if (response.data.statuscode === 500 || response.data.status === 500) {
                this.gettingLink = false
                if (response.data.result === 'invalid bot server key!') {
                  this.$notice.alert('Inserire una chiave segreta corretta')
                } else {
                  this.$notice.alert("Errore durante l'upload dell'immagine")
                }
                return
              } else {
                parameters = parameters + `&fileId=${response.data.result}`
              }
            } else {
              this.gettingLink = false
              this.$notice.alert("Errore durante l'upload dell'immagine")
              return
            }
          } catch (error) {
            this.gettingLink = false
            this.$notice.alert("Errore durante l'upload dell'immagine")
            return
          }
        } else if (this.data.mode === 'screen') {
          this.gettingLink = false
          this.$notice.alert('Aggiorna lo sfondo')
          return
        }

        const url = `${process.env.VUE_APP_DOMAIN_URL}${parameters}`
        navigator.clipboard.writeText(url).then(() => {
          this.gettingLink = false
          this.$notice.success('Link copiato')
        }).catch(() => {
          this.gettingLink = false
        })
      } catch (error) {
        console.log(error)
        this.gettingLink = false
      }
    }
  }
}
